import * as React from "react"
import Layout from "../../components/layout"
import Servicelinks from "../../components/servicelinks"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'
import useIntersection from '../../components/useIntersection'; // import the custom hook
import classNames from 'classnames';

const Section = ({  id, className, children }) => {
  const [ref, isIntersecting] = useIntersection({
    root: null, // Use the viewport as the root
    rootMargin: '0px',
    threshold: 0.3 // Trigger when 50% of the element is in view
  });

  return (
    <section
      id={id}
      ref={ref}
      className={classNames(className, { 'not-in-view': !isIntersecting })}
    >
      {children}
    </section>
  );
};

const ApiDev = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'service-page'
    }}
/>
  <Layout pageTitle="ApiDev">
    <section id="main">
      <div id="service-intro">
        <div className="left-col">
<h1><span className="revealblack">API Integration Development</span></h1>
<p><span className="revealblack">With our web development expertise anything is possible with API integration development.</span></p>
<a className="button sendusemail" href="#sendusemail"><span className="seemore"><i>&#xe801;</i></span>Send us an email</a>
        </div>
        <div className="right-col">
            <img className="picture" src={'/img/services/tobstar-mobile-responsive-header.jpg'} alt="API Development" />
        </div>
      </div>
    </section>

    <Section id="service-content">
      <div id="inner-content">

        <h2>Integrating social media</h2>
<p>We provide social media tools for your websites such as API development for your social feeds from Twitters, Facebook, Instagram into your website.</p>
<p>With <AniLink cover to="/capabilities/wordpress-development-melbourne" bg="#00ccd4" direction="right" duration={0.76}>WordPress</AniLink>, we provide a plugin to auto posts into your social media’s accounts such as on your Facebook’s timeline, Linkedin’s posts and into your Tweets directly from your WordPress site. Also enable sharing tool on the pages.</p>
<h2>Integrating web applications with Xero and MYOB</h2>
<p>We provide API integration development with MYOB and Xero, syncing with your online business such as bookings systems, manage contacts and staff, customer orders, invoicing, inventory and payslips.</p>
<p>With Xero and MYOB API, which allows many of the features of the software to be used by a web application, such as your website or business management tools.</p>
<p>Xero and MYOB API has the function to send email invoices to your customers via the API. It's where web application can control all the invoice and purchasing functions via the API, businesses can fully integrate their system, generating invoices automatically.</p>
<h2>and much more integrating such as</h2>
<ul>
<li>VIMEO API</li>
<li>Customising Google Map API</li>
<li>Twilio SMS Messaging API</li>
<li>Mailchimp API for newsletter subscription</li>
<li>Salesforce API</li>
<li>Payment Gateway API eg Stripe, BPOINT, eWay etc</li>
<li>3rd party Customer Relationship Managment (CRM) API</li>
</ul>


      </div>
       <Servicelinks></Servicelinks>
   </Section>
</Layout>

  </>
  )
}

export default ApiDev


export const Head = () => (
  <>
    <title>API Integration Development - Tobstar</title>
<meta name="description" content="We brand, strategise, design and app development powerful applications on the major mobile and tablet operating systems, Apple’s iOS and Google’s Android." />
  </>
)